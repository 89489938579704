import { t, Trans } from '@lingui/macro';
import { Theme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Billable } from '../duplicated/Billable';
import { Call } from '../duplicated/Call';
import useVideoContext from '../hooks/useVideoContext/useVideoContext';
import { FBClient } from './FBClient';

var isBillingIntervalsOn = false;
const minute = 60 * 1000;
export default function Taxameter() {
  const [duration, setDuration] = useState(0);
  const [extend, setExtend] = useState(false);
  const [hostIsConnected, setHostIsConnected] = useState(false);
  const [billingOn, setBillingOn] = useState(false);
  const { room } = useVideoContext();

  const currentCall = FBClient.shared.currentCall;

  const rate = currentCall?.offer?.ratePerMin ?? 0;
  const isPaidCall = (currentCall?.offer?.ratePerMin ?? 0) > 0;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log('Taxameter useEffect called');

    if (!currentCall || !currentCall.offer?.host?.id) {
      console.warn('No current call or host');
      return;
    }

    startStopBilling();

    room?.on('participantConnected', p => {
      console.log('participantConnected', p.identity);
      if (p.identity == FBClient.shared.currentCall?.offer?.host?.id)
        setHostIsConnected(true);

      startStopBilling();
    });
    room?.on('participantDisconnected', p => {
      console.log('participantDisconnected', p.identity);
      if (p.identity == FBClient.shared.currentCall?.offer?.host?.id)
        setHostIsConnected(false);

      startStopBilling();
    });

    if (isPaidCall) {
      return FBClient.shared.subscribeToBillables(currentCall.id!, nrOfMinutes => setDuration(nrOfMinutes));
    }
  }, []);

  function startStopBilling() {
    let isHost = FBClient.shared.isHostOfCurrentCall();
    let _hostIsConnected = isHost;

    if (!isHost) {
      room?.participants.forEach(p => {
        if (p.identity == FBClient.shared.currentCall?.offer?.host?.id) _hostIsConnected = true;
      });
    }

    if (isPaidCall && _hostIsConnected && (room?.participants.size ?? 0) > 0) {
      setBillingOn(true);

      if (FBClient.shared.isHostOfCurrentCall() && !isBillingIntervalsOn) {
        console.log('Starting billing interval.');
        isBillingIntervalsOn = true;

        let id = setInterval(() => {
          if (room?.participants.size ?? 0 > 0) {
            addBillable();
          } else {
            console.log('No participants, stopped billing');
            clearInterval(id);
            setBillingOn(false);
            isBillingIntervalsOn = false;
          }
        }, minute);
      }
    } else {
      setBillingOn(false);
      console.log('Not billing');
    }
    setHostIsConnected(_hostIsConnected);
  }

  function addBillable() {
    let unixTime = new Date().getTime();
    let payerId = Call.getPayer(currentCall!)?.id ?? '';
    let payeeId = Call.getPayee(currentCall!)?.id ?? '';
    FBClient.shared.addBillable(new Billable(unixTime, currentCall!.id!, payerId, payeeId));
  }

  return (
    <div className={isMobile ? 'mobile' : ''}>
      <div id="taxameter" className={`${billingOn ? 'billing-on' : 'billing-off'} noselect hidable`} onClick={() => setExtend(!extend)}>
        {isPaidCall ? (
          <>
            {!extend && (<span>&nbsp;{duration * rate} {currentCall?.offer?.currency} {billingOn ? '' : t`(paused)`}</span>)}
            {extend && getExtendedInfo()}
          </>)
          :
          (<span><Trans>No cost</Trans></span>)}
        {!hostIsConnected && (<span>&nbsp;| <Trans>The host is not connected</Trans></span>)}
      </div>
    </div>
  );

  function getExtendedInfo() {
    if (isPaidCall) {
      return (
        <span>
          <Trans>
            The bill {billingOn ? '' : t`(paused)` + ' '}for {currentCall?.part?.displayName ?? 'error'} is total{' '}
            {duration * rate} {currentCall?.offer?.currency} for {duration} minutes (the rate is {rate}{' '}
            {currentCall?.offer?.currency}/min).
          </Trans>
        </span>
      );
    } else {
      return <span></span>;
    }
  }
}
