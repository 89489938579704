import { IonProgressBar } from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { I18nProvider } from '@lingui/react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import { ChatProvider } from './components/ChatProvider';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from "./components/LoginPage/LoginPage";
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { VideoProvider } from './components/VideoProvider';
import { DEFAULT_LANGUAGE } from "./locales/translation";
import CallLogFrame from './ringeling/CallLogFrame';
import { FBClient } from "./ringeling/FBClient";
import AppStateProvider, { useAppState } from './state';
import './style.scss';
import theme from './theme';
import './types';
import { useParams } from "react-router-dom";
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';

i18n.activate(DEFAULT_LANGUAGE);

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();
  const [loggedIn, setLoggedIn] = useState(false);
  const params = useParams<{ site: string, callId: string}>();

  useEffect(() => {
    FBClient.shared.currentSite = params.site;
    return FBClient.shared.setAuthChangedHandler(user => {
      setLoggedIn(!!user);
    });
  }, [])

  return (
    <div id="mainframe">
      <CallLogFrame />
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <ChatProvider>
          {loggedIn && <App />}
          {!loggedIn && <IonProgressBar type='indeterminate'/>}
        </ChatProvider>
      </VideoProvider>
    </div>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <I18nProvider i18n={i18n}>
          <AppStateProvider>
            <Switch>
              <Route exact path="/">
                <h1><Trans>Connect to the meeting room from the meeting page.</Trans></h1>
              </Route>
              <Route exact path="/:site/:callId/:token">
                <LoginPage />
              </Route>
              <Route exact path="/:site/:callId">
                <VideoApp />
              </Route>
            </Switch>
          </AppStateProvider>
        </I18nProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);
