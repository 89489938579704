import { Button, Theme, useMediaQuery } from '@material-ui/core';
import { CloseOutlined, ListAltOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { FBClient } from './FBClient';

export default function CallLogFrame() {

  const [show, setShow] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  //`${isMobile ? 'mobile':'desktop'} 
  return (<div id="ringeling-main" className={`hidable`} >
    <Button
      variant="contained"
      color="primary"
      id="toggle-log" className={show ? 'chat-visible' : 'chat-hidden'} onClick={() => setShow(!show)}> {show ? <CloseOutlined /> : <ListAltOutlined />}</Button>
    {show && <>
      <iframe src={`https://${FBClient.shared.currentCall?.offer?.site!}/call_assist/${FBClient.shared.currentCall?.id}`} frameBorder="0" />
    </>
    }
  </div>
  );
}