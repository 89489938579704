import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { FBClient } from '../../ringeling/FBClient';
import IntroContainer from '../IntroContainer/IntroContainer';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  // const { user } = useAppState();
  const user = FBClient.shared.user;
  const { getAudioAndVideoTracks } = useVideoContext();
  const { callId } = useParams() as any;
  const [step, setStep] = useState(Steps.deviceSelectionStep);
  // const [roomName, setRoomName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (callId) {
      // setRoomName(callId);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, callId]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
  //   if (!window.location.origin.includes('twil.io')) {
  //     window.history.replaceState(null, '', window.encodeURI(`/room/${callId}${window.location.search || ''}`));
  //   }
  //   setStep(Steps.deviceSelectionStep);
  // };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen displayName={user?.displayName || 'N/A'} callId={callId} />
      )}
    </IntroContainer>
  );
}
