import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { FBClient } from '../../ringeling/FBClient';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
    marginRight: '5px',
    float: 'right'
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <div className='hidable'>
      <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
        {/* <Typography variant="subtitle1">{room!.name}</Typography> */}
        <Typography className="oneline" variant="subtitle1">{`${FBClient.shared.currentCall?.offer?.title}` ?? "Ingen titel"}</Typography>
        <div>
          <EndCallButton className={classes.endCallButton} />
          {/* <Menu buttonClassName={classes.settingsButton} /> */}
        </div>
      </Grid>
    </div>
  );
}
