import { IonProgressBar } from '@ionic/react';
import { t, Trans } from '@lingui/macro';
import { Button, Grid, Hidden, makeStyles, Theme, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import { ROOM_TYPE_GO, ROOM_TYPE_GROUP, ROOM_TYPE_P2P } from '../../../constants';
import { Call } from '../../../duplicated/Call';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { FBClient } from '../../../ringeling/FBClient';
import { useAppState } from '../../../state';
import { DateUtil } from '../../../utils/DateUtil';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';

interface DeviceSelectionScreenProps {
  displayName: string;
  callId: string;
}

export default function DeviceSelectionScreen({ displayName, callId }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [call, setCall] = useState<Call>();
  const [readyToConnect, setReadyToConnect] = useState(false);
  const [isConnectingToRoom, setIsConnectingToRoom] = useState(false);

  useEffect(() => {
    FBClient.shared.loadCall(callId).then(call => {
      if (call) {
        setCall(call);
        setReadyToConnect(call.status == 'booked');
      }
    });
  }, [callId]);

  const handleJoin = () => {
    if (call) {
      setIsConnectingToRoom(true);
      let roomType = ROOM_TYPE_P2P;
      if (call.userIds?.length > 2) {
        roomType = ROOM_TYPE_GROUP;
      }

      FBClient.shared.createRoomAndGetTwilioToken(call.id!, roomType).then(({ token }) => {
        videoConnect(token);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
        setIsConnectingToRoom(false);
      });
    }
  };

  function goToCall() {
    let site = call?.offer?.site;
    let s = site?.includes('localhost') ? '' : 's';
    window.location.href = `http${s}://${site}/call/${call?.id}`;
  }

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            <Trans>Connecting to the room</Trans>
          </Typography>
        </div>
      </Grid>
    );
  } else
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          {call?.offer?.title ?? t`No meeting room found`}
        </Typography>
        {call && call.status == 'booked' && !call.isArchived ? (
          <Typography variant="body1" className={classes.gutterBottom}>
            <Trans>
              Starting at {DateUtil.dateStrToTimeAndDay(call?.bookedDate, FBClient.shared.user?.lang ?? 'en') ?? ''}
            </Trans>
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.gutterBottom}>{t`This meeting is inactive`}</Typography>
        )}

        <Grid container justifyContent="center">
          <Grid item md={7} sm={12} xs={12}>
            <div className={classes.localPreviewContainer}>
              <LocalVideoPreview identity={displayName} />
            </div>
            <div className={classes.mobileButtonBar}>
              <Hidden mdUp>
                <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              </Hidden>
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </div>
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
              <div>
                <Hidden smDown>
                  <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                  <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
                </Hidden>
              </div>
              {isConnectingToRoom && <IonProgressBar type="indeterminate" />}
              <div className={classes.joinButtons}>
                {/* <Button variant="outlined" color="primary" onClick={() => setStep(Steps.roomNameStep)}>Cancel</Button> */}
                <Button variant="outlined" color="primary" onClick={goToCall}>
                  <Trans>Back</Trans>{' '}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons || !readyToConnect || isConnectingToRoom}
                >
                  <Trans>Connect to the room</Trans>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));
